import React, { useState } from 'react';
import { ArrowUpRight, Newspaper, Bell, X, Target, Globe, BarChart2, Brain, Search, Users, User, Mail, Phone, CircuitBoard, Rocket, Quote} from 'lucide-react';
import webBackground from './web_background1.jpg';
import { useEffect } from 'react';
import {API_URL} from "./api";

const style = document.createElement('style');
style.textContent = `
        @keyframes pulse {
            0%, 100% { opacity: 0.4; }
            50% { opacity: 0.8; }
        }
    `;
document.head.appendChild(style);

const GoogleAnalytics = () => {
    useEffect(() => {
        // Create script elements
        const firstScript = document.createElement('script');
        firstScript.async = true;
        firstScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-ERYNPLEP7Y';

        const secondScript = document.createElement('script');
        secondScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-ERYNPLEP7Y');
    `;

        // Append scripts to document head
        document.head.appendChild(firstScript);
        document.head.appendChild(secondScript);

        // Cleanup function
        return () => {
            document.head.removeChild(firstScript);
            document.head.removeChild(secondScript);
        };
    }, []); // Empty dependency array means this runs once on mount

    return null; // This component doesn't render anything
};

const SectionTitle = ({ children }) => {
    return (
        <div className="text-center mb-12">
            <h2 className="text-3xl font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm">
                {children}
            </h2>
            <div className="h-1 w-24 bg-gradient-to-r from-blue-400/50 to-pink-400/50 mx-auto mt-4 rounded-full
                          group-hover:w-32 transition-all duration-300" />
        </div>
    );
};


const TypedText = () => {
    const [displayedText, setDisplayedText] = useState('');
    const [showCursor, setShowCursor] = useState(true);
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const beforeBlue = 'Make informed currency exchange decisions with ';
    const blueText = 'AI-powered event reasoning ';
    const afterBlue = 'Coming soon ...';
    const fullText = beforeBlue + blueText + afterBlue;

    // Typing effect
    useEffect(() => {
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            if (currentIndex <= fullText.length) {
                setDisplayedText(fullText.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(intervalId);
                setIsTypingComplete(true);
                setShowCursor(false); // Hide cursor when typing is complete
            }
        }, 50);

        return () => clearInterval(intervalId);
    }, []);

    // Blinking cursor effect - only active while typing
    useEffect(() => {
        if (isTypingComplete) return; // Don't blink cursor if typing is complete

        const cursorInterval = setInterval(() => {
            setShowCursor(prev => !prev);
        }, 530);

        return () => clearInterval(cursorInterval);
    }, [isTypingComplete]);

    return (
        <p className="text-xl mb-8 font-bold text-white drop-shadow-md max-w-3xl mx-auto">
            {beforeBlue.slice(0, displayedText.length)}
            {displayedText.length > beforeBlue.length && (
                <span className="text-blue-600">
                    {blueText.slice(0, displayedText.length - beforeBlue.length)}
                </span>
            )}
            {displayedText.length > beforeBlue.length + blueText.length &&
                afterBlue.slice(0, displayedText.length - (beforeBlue.length + blueText.length))
            }
            <span className="text-white">{showCursor ? '|' : ''}</span>
        </p>
    );
};


const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [currentPage, setCurrentPage] = useState('home');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/api/waitlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                setIsModalOpen(false);
                setFormData({ name: '', email: '', phone: '' });
            } else {
                alert(data.message || 'Error submitting data');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error connecting to server');
        }
    };

  const renderPage = () => {
    switch(currentPage) {
      // case 'terms':
      //   return <TermsOfService />;
      // case 'privacy':
      //   return <PrivacyPolicy />;
      default:
        return (
            <>
                <section
                    id="features" className="relative min-h-[70vh] w-full flex items-center justify-center"
                >
                    {/* Background image with proper sizing */}
                    <div
                        className="absolute inset-0 w-full h-[70vh]"
                        style={{
                            backgroundImage: `url(${webBackground})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            opacity: 0.9
                        }}
                    ></div>

                    {/* Content container */}
                    <div className="container mx-auto px-6 py-16 text-center relative z-10">
                        <h1 className="text-5xl font-bold mb-6 text-white drop-shadow-lg">
                            Plan Your Currency Exchanges
                            <br/>
                            with <span className="text-blue-600">FutCon AI</span>
                        </h1>
                        <TypedText/>
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="relative group px-8 py-3 rounded-xl text-lg font-bold text-white
                          bg-gradient-to-br from-blue-600 to-purple-600
                          hover:from-blue-500 hover:to-purple-500
                          transition-all duration-300 ease-in-out
                          shadow-lg hover:shadow-xl
                          overflow-hidden"
                        >
                            {/* Glowing effect */}
                            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent
                              translate-x-[-200%] group-hover:translate-x-[200%]
                              transition-transform duration-1000 ease-in-out"/>

                            {/* Button content */}
                            <span className="relative z-10">Join the Waitlist</span>

                            {/* Corner accents */}
                            <div className="absolute top-0 left-0 w-2 h-2 border-t border-l border-white/40"/>
                            <div className="absolute top-0 right-0 w-2 h-2 border-t border-r border-white/40"/>
                            <div className="absolute bottom-0 left-0 w-2 h-2 border-b border-l border-white/40"/>
                            <div className="absolute bottom-0 right-0 w-2 h-2 border-b border-r border-white/40"/>
                        </button>
                    </div>
                </section>

                <section id="features" className="relative py-16 overflow-hidden">
                    {/* Gradient Background */}
                    <div className="absolute inset-0 bg-gradient-to-br from-yellow-50 via-pink-50 to-blue-50"/>

                    {/* Content */}
                    <div className="relative container mx-auto px-6">
                        {/*<h2 className="text-3xl text-center font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm mb-12">*/}
                        {/*    Why Choose FutCon AI*/}
                        {/*</h2>*/}
                        <SectionTitle>Why Choose FutCon AI</SectionTitle>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <FeatureCard
                                icon={<Search className="w-12 h-12"/>}
                                title="Finding the Best Exchange Platform"
                                description="We compare multiple platforms to connect you with the most suitable option for your specific needs, saving you time and effort."
                            />
                            <FeatureCard
                                icon={<ArrowUpRight className="w-12 h-12"/>}
                                title="Smart Currency Exchanges Planning"
                                description="Users delay transactions due to poor rates. Our AI predicts optimal exchange times, helping you plan ahead for better rates."
                            />
                            <FeatureCard
                                icon={<Newspaper className="w-12 h-12"/>}
                                title="AI Event Reasoning and Analysis"
                                description="Our AI scans and interprets global events and news, providing you with concise, relevant insights on factors influencing your target currency pair. Understand market movements and make informed decisions with ease."
                            />
                            <FeatureCard
                                icon={<Bell className="w-12 h-12"/>}
                                title="Real-Time Rate Monitoring"
                                description="No need to manually track rates. We offer automated alerts and predictions, allowing you to focus on other priorities."
                            />
                        </div>
                    </div>
                </section>

                <section id="how-it-works" className="relative py-16 overflow-hidden">
                    {/* Gradient Background */}
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-pink-50 to-yellow-50"/>


                    {/* Content */}
                    <div className="relative container mx-auto px-6">
                        {/*<h2 className="text-3xl text-center font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm mb-12">*/}
                        {/*    How FutCon AI Works*/}
                        {/*</h2>*/}
                        <SectionTitle>How FutCon AI Works</SectionTitle>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                            <HowItWorksStep
                                icon={<Target/>}
                                title="1. Set Your Target"
                                description="Select your currency pair and define your exchange goals. Our AI adapts to your specific needs and preferences."
                            />
                            <HowItWorksStep
                                icon={<Globe/>}
                                title="2. AI Monitors Markets"
                                description="Our advanced AI continuously scans global events, analyzes market patterns, and tracks exchange rates across platforms."
                            />
                            <HowItWorksStep
                                icon={<BarChart2/>}
                                title="3. Receive Insights"
                                description="Get real-time notifications about market trends, optimal exchange times, and AI-powered rate predictions."
                            />
                            <HowItWorksStep
                                icon={<Brain/>}
                                title="4. Make Decisions"
                                description="Use our comprehensive AI analysis to choose the best platform and timing for your currency exchanges."
                            />
                        </div>
                    </div>
                </section>

                <section id="about" className="relative py-20 overflow-hidden">
                    {/* Animated Background Elements */}
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-pink-50 to-yellow-50" />

                    {/* Floating orbs */}
                    <div className="absolute top-20 left-20 w-64 h-64 bg-blue-400/20 rounded-full blur-3xl animate-pulse" />
                    <div className="absolute bottom-20 right-20 w-64 h-64 bg-pink-400/20 rounded-full blur-3xl animate-pulse delay-700" />

                    <div className="relative container mx-auto px-6">
                        {/* Title with rocket effect */}
                        <div className="flex items-center justify-center mb-8 hover:-translate-y-1 transition-transform duration-300">
                            {/*<h2 className="text-4xl text-center font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm">*/}
                            {/*    About FutCon AI*/}
                            {/*</h2>*/}
                            <SectionTitle>About FutCon AI</SectionTitle>
                            <RocketToMars />
                        </div>

                        {/* Main Mission Statement */}
                        <div
                            className="max-w-4xl mx-auto text-center mb-16 hover:scale-[1.02] transition-transform duration-300">
                            <p className="text-xl text-gray-800 font-semibold leading-relaxed">
                                We are a team of passionate computer science researchers and engineers with diverse
                                international backgrounds,
                                united by a common goal: <span className="relative inline-block">
                            <span className="relative z-10 text-blue-600">making international transactions safer and more accessible for everyone</span>
                            <span className="absolute bottom-0 left-0 w-full h-2 bg-blue-100/60 -z-10 skew-x-3
                                         group-hover:bg-blue-200/80 transition-colors duration-300"/>
                        </span>
                            </p>
                        </div>

                        {/* Mission Details Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                            {/* Who We Help Card */}
                            <div className="group bg-white/90 backdrop-blur-md p-8 rounded-2xl shadow-lg border border-blue-100
                                  hover:shadow-xl hover:border-blue-200 hover:-translate-y-1 transition-all duration-300">
                                <div className="flex items-center mb-6">
                                    <div className="p-3 bg-gradient-to-br from-blue-100 to-pink-100 rounded-xl mr-4
                                          group-hover:scale-110 transition-transform duration-300">
                                        <Users className="w-6 h-6 text-blue-600"/>
                                    </div>
                                    <h3 className="text-xl font-bold text-blue-600">Who We Help</h3>
                                </div>
                                <ul className="space-y-4 text-gray-700 font-semibold">
                                    {[
                                        "Immigrants navigating new financial systems",
                                        "International students managing tuition and living expenses",
                                        "International travelers managing travel expenses",
                                        "Professionals working abroad",
                                        "Small international business owners"
                                    ].map((item, index) => (
                                        <li key={index}
                                            className="flex items-center group/item hover:translate-x-2 transition-transform duration-300">
                                            <div
                                                className="w-2 h-2 bg-gradient-to-r from-blue-400 to-pink-400 rounded-full mr-3"/>
                                            <span
                                                className="hover:text-blue-600 transition-colors duration-300">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Our Approach Card */}
                            <div className="group bg-white/90 backdrop-blur-md p-8 rounded-2xl shadow-lg border border-blue-100
                                  hover:shadow-xl hover:border-blue-200 hover:-translate-y-1 transition-all duration-300">
                                <div className="flex items-center mb-6">
                                    <div className="p-3 bg-gradient-to-br from-blue-100 to-pink-100 rounded-xl mr-4
                                          group-hover:scale-110 transition-transform duration-300">
                                        <Brain className="w-6 h-6 text-blue-600"/>
                                    </div>
                                    <h3 className="text-xl font-bold text-blue-600">Our Approach</h3>
                                </div>
                                <ul className="space-y-4 text-gray-700 font-semibold">
                                    {[
                                        "Leveraging advanced AI for market analysis",
                                        "Simplifying complex financial decisions",
                                        "Providing real-time market insights",
                                        "Protecting users from currency volatility"
                                    ].map((item, index) => (
                                        <li key={index}
                                            className="flex items-center group/item hover:translate-x-2 transition-transform duration-300">
                                            <div
                                                className="w-2 h-2 bg-gradient-to-r from-blue-400 to-pink-400 rounded-full mr-3"/>
                                            <span
                                                className="hover:text-blue-600 transition-colors duration-300">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* Bottom Statement */}
                        <div className="max-w-4xl mx-auto mt-16">
                            <div className="relative group">
                                {/* Decorative background elements */}
                                <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-pink-600 rounded-2xl blur opacity-20
                                  group-hover:opacity-30 transition-opacity duration-300"/>

                                {/* Main content container */}
                                <div
                                    className="relative p-0.5 rounded-2xl bg-gradient-to-r from-blue-500/20 to-pink-500/20">
                                    <div className="relative px-8 py-8 bg-white/90 backdrop-blur-sm rounded-2xl">
                                        {/* Quote icon top */}
                                        <div className="absolute -top-4 left-8">
                                            <div
                                                className="p-2 bg-gradient-to-br from-blue-600 to-pink-400 rounded-full">
                                                <Quote className="w-5 h-5 text-white"/>
                                            </div>
                                        </div>

                                        {/* Mission text */}
                                        <div className="pt-4">
                                            <p className="text-lg text-gray-800 font-medium leading-relaxed">
                                    <span
                                        className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-pink-500 bg-clip-text text-transparent">
                                        In today's unstable global environment,
                                    </span>{" "}
                                                we understand the challenges of international transactions.
                                            </p>
                                            <div
                                                className="h-0.5 w-1/3 bg-gradient-to-r from-blue-400/50 to-pink-400/50 my-4 rounded-full"/>
                                            <p className="text-lg text-gray-800 font-medium leading-relaxed">
                                                Our mission is to{" "}
                                                <span className="relative inline-block">
                                        <span
                                            className="relative z-10">empower those with limited financial knowledge</span>
                                        <span
                                            className="absolute bottom-0 left-0 w-full h-2 bg-blue-100/60 -z-10 skew-x-3"/>
                                    </span>{" "}
                                                to navigate currency exchanges confidently, helping them{" "}
                                                <span className="relative inline-block">
                                        <span className="relative z-10">avoid unnecessary risks</span>
                                        <span
                                            className="absolute bottom-0 left-0 w-full h-2 bg-pink-100/60 -z-10 -skew-x-3"/>
                                    </span>{" "}
                                                and make informed decisions.
                                            </p>
                                        </div>

                                        {/* Decorative elements */}
                                        <div
                                            className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-br from-blue-400/10 to-transparent rounded-br-2xl"/>
                                        <div
                                            className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-pink-400/10 to-transparent rounded-bl-2xl"/>

                                        {/* Corner accents */}
                                        <div
                                            className="absolute top-4 right-4 w-4 h-4 border-t-2 border-r-2 border-blue-300/30 rounded-tr"/>
                                        <div
                                            className="absolute bottom-4 left-4 w-4 h-4 border-b-2 border-l-2 border-pink-300/30 rounded-bl"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </section>
            </>
      );
      }
      };

      return (
          <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
              <GoogleAnalytics />
              <header className="bg-white shadow-md">
                  <nav className="container mx-auto px-6 py-3">
                      <div className="flex justify-between items-center">
                          <div className="text-2xl font-bold text-blue-600">FutCon AI</div>
                          {/*<div className="space-x-4">*/}
                          {/*  <a href="#features" className="text-gray-600 hover:text-blue-600">Features</a>*/}
                          {/*  <a href="#how-it-works" className="text-gray-600 hover:text-blue-600">How It Works</a>*/}
                          {/*  <a href="#about" className="text-gray-600 hover:text-blue-600">About Us</a>*/}
                          {/*  <button onClick={() => setIsModalOpen(true)} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">Join Waitlist</button>*/}
                          {/*</div>*/}
                      </div>
                  </nav>
              </header>

              <main>
                  {renderPage()}
              </main>

              <footer className="bg-gray-800 text-white py-8">
                  <div className="container mx-auto px-6">
                      <div className="flex flex-col md:flex-row justify-between items-center">
                          <div className="mb-4 md:mb-0">
                              <span className="text-2xl font-bold">FutCon AI</span>
                          </div>
                          <div className="flex space-x-4">
                              {/*<button onClick={() => setCurrentPage('terms')} className="hover:text-blue-300">Terms of Service</button>*/}
                              {/*<button onClick={() => setCurrentPage('privacy')} className="hover:text-blue-300">Privacy Policy</button>*/}
                              <a href="mailto:info@futconai.com" className="hover:text-blue-300">Contact Us</a>
                          </div>
                      </div>
                      <div className="mt-8 text-center">
                          <p>&copy; 2024 FutCon AI. All rights reserved.</p>
                      </div>
                  </div>
              </footer>

              {isModalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                      {/* Backdrop with blur */}
                      <div
                          className="absolute inset-0 bg-black/30 backdrop-blur-sm"
                          onClick={() => setIsModalOpen(false)}
                      />

                      {/* Modal Container */}
                      <div className="relative w-full max-w-md transform transition-all duration-300 scale-100">
                          {/* Gradient border effect */}
                          <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-pink-600 rounded-2xl blur opacity-75" />

                          {/* Modal Content */}
                          <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-xl">
                              {/* Close Button */}
                              <button
                                  onClick={() => setIsModalOpen(false)}
                                  className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
                              >
                                  <X className="w-5 h-5 text-gray-500" />
                              </button>

                              {/* Header */}
                              <div className="mb-8">
                                  <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-pink-600 bg-clip-text text-transparent">
                                      Join our Waitlist
                                  </h2>
                                  <p className="text-gray-600 dark:text-gray-400 mt-2">
                                      Be the first to experience the future of currency exchange
                                  </p>
                              </div>

                              {/* Form */}
                              <form onSubmit={handleSubmit} className="space-y-6">
                                  {/* Name Input */}
                                  <div className="relative">
                                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                          Name
                                      </label>
                                      <div className="relative">
                                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                              <User className="h-5 w-5 text-gray-400" />
                                          </div>
                                          <input
                                              type="text"
                                              id="name"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleInputChange}
                                              className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg
                                             bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white
                                             focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600
                                             focus:border-transparent transition-colors duration-200"
                                              required
                                          />
                                      </div>
                                  </div>

                                  {/* Email Input */}
                                  <div className="relative">
                                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                          Email
                                      </label>
                                      <div className="relative">
                                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                              <Mail className="h-5 w-5 text-gray-400" />
                                          </div>
                                          <input
                                              type="email"
                                              id="email"
                                              name="email"
                                              value={formData.email}
                                              onChange={handleInputChange}
                                              className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg
                                             bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white
                                             focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600
                                             focus:border-transparent transition-colors duration-200"
                                              required
                                          />
                                      </div>
                                  </div>

                                  {/* Phone Input */}
                                  <div className="relative">
                                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                          Phone Number
                                      </label>
                                      <div className="relative">
                                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                              <Phone className="h-5 w-5 text-gray-400" />
                                          </div>
                                          <input
                                              type="tel"
                                              id="phone"
                                              name="phone"
                                              value={formData.phone}
                                              onChange={handleInputChange}
                                              className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg
                                             bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white
                                             focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600
                                             focus:border-transparent transition-colors duration-200"
                                              required
                                          />
                                      </div>
                                  </div>

                                  {/* Submit Button */}
                                  <button
                                      type="submit"
                                      className="w-full relative py-3 px-4 bg-gradient-to-r from-blue-600 to-pink-600 text-white font-bold
                                     rounded-lg transform hover:-translate-y-0.5 transition-all duration-200
                                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                                     disabled:opacity-50 disabled:cursor-not-allowed group"
                                  >
                            <span className="absolute inset-0 w-full h-full rounded-lg bg-gradient-to-r from-blue-600 to-pink-600 opacity-0
                                           group-hover:opacity-50 transition-opacity duration-200" />
                                      <span className="relative flex items-center justify-center">
                                Join Waitlist
                                <span className="ml-2">→</span>
                            </span>
                                  </button>
                              </form>
                          </div>
                      </div>
                  </div>
            )}
        </div>
    );
};

const RocketToMars = () => (
    <div className="relative ml-4 w-12 h-12">
        {/* Mars */}
        <div className="absolute -right-2 -top-2 w-4 h-4 rounded-full bg-gradient-to-br from-red-400 to-red-600" />

        {/* Orbit path */}
        <div className="absolute inset-0 rounded-full border border-dashed border-blue-200 opacity-50 animate-[spin_4s_linear_infinite]" />

        {/* Rocket */}
        <div className="absolute left-0 top-0 animate-[spin_4s_linear_infinite] origin-center">
            <div className="relative -top-1 -left-1 animate-[counter-spin_4s_linear_infinite]">
                <Rocket className="w-5 h-5 text-blue-600 rotate-45" />

                {/* Rocket thrust effect */}
                <div className="absolute -bottom-1 -left-0.5 w-2 h-2 bg-gradient-to-t from-orange-400 to-yellow-200 rounded-full animate-pulse" />
            </div>
        </div>
    </div>
);

const IconWrapper = ({children}) => (
    <div className="relative group w-16 h-16">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400/20 to-pink-400/20 rounded-xl"/>

        {/* Icon container */}
        <div className="relative w-full h-full flex items-center justify-center bg-white/80 rounded-xl backdrop-blur-sm border border-white/20 shadow-lg
                      group-hover:scale-105 transition-transform duration-300">
            {children}

            {/* Corner accents */}
            <div className="absolute top-0 left-0 w-2 h-2 border-t border-l border-blue-400 opacity-60"/>
            <div className="absolute top-0 right-0 w-2 h-2 border-t border-r border-pink-400 opacity-60"/>
            <div className="absolute bottom-0 left-0 w-2 h-2 border-b border-l border-blue-400 opacity-60" />
            <div className="absolute bottom-0 right-0 w-2 h-2 border-b border-r border-pink-400 opacity-60" />
        </div>
    </div>
);

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="relative w-full h-48 group overflow-hidden">
            {/* Front of card */}
            <div className="absolute w-full h-full bg-gradient-to-br from-white to-white/70 p-6 rounded-2xl shadow-lg flex flex-row items-center justify-start text-left
                          transition-transform duration-500 ease-in-out group-hover:-translate-x-full">
                {/* Enhanced icon wrapper */}
                <div className="mr-6">
                    <IconWrapper>
                        {React.cloneElement(icon, {
                            className: "w-8 h-8 text-transparent bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text stroke-blue-600"
                        })}
                    </IconWrapper>
                </div>
                <div>
                    <h3 className="text-xl font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm">
                        {title}
                    </h3>
                    <div className="h-0.5 w-1/3 bg-gradient-to-r from-blue-400/50 to-pink-400/50 mt-2 rounded-full" />
                </div>
            </div>

            {/* Back of card */}
            <div className="absolute w-full h-full bg-gradient-to-br from-blue-600/90 to-pink-600/90 p-8 rounded-2xl shadow-lg
                          transition-transform duration-500 ease-in-out translate-x-full group-hover:translate-x-0
                          overflow-hidden">

                {/* Glowing Lines */}
                <div className="absolute inset-0">
                    <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-400/50 to-transparent animate-pulse" />
                    <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-pink-400/50 to-transparent animate-pulse delay-300" />
                    <div className="absolute left-0 top-0 w-px h-full bg-gradient-to-b from-transparent via-blue-400/50 to-transparent animate-pulse delay-150" />
                    <div className="absolute right-0 top-0 w-px h-full bg-gradient-to-b from-transparent via-pink-400/50 to-transparent animate-pulse delay-500" />
                </div>

                {/* Content Container */}
                <div className="relative h-full flex flex-col justify-center">
                    <p className="text-lg font-bold text-white drop-shadow-lg bg-clip-text">
                        {description}
                    </p>

                    {/* Animated Corner Accents */}
                    <div className="absolute top-0 left-0 w-4 h-4 border-l-2 border-t-2 border-blue-300/60 rounded-tl" />
                    <div className="absolute top-0 right-0 w-4 h-4 border-r-2 border-t-2 border-pink-300/60 rounded-tr" />
                    <div className="absolute bottom-0 left-0 w-4 h-4 border-l-2 border-b-2 border-blue-300/60 rounded-bl" />
                    <div className="absolute bottom-0 right-0 w-4 h-4 border-r-2 border-b-2 border-pink-300/60 rounded-br" />
                </div>
            </div>
        </div>
    );
};


const HowItWorksStep = ({ icon, title, description }) => {
    return (
        <div className="relative group">
            {/* Card container with gradient border */}
            <div className="relative p-6 rounded-2xl bg-white/90 backdrop-blur-sm
                          border border-transparent hover:border-blue-200/30
                          transition-all duration-300 hover:shadow-lg">
                {/* Background gradient */}
                <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 to-pink-50/50 rounded-2xl" />

                {/* Icon container */}
                <div className="relative mb-6">
                    <div className="w-20 h-20 mx-auto relative group">
                        {/* Icon background effects */}
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-100/50 to-pink-100/50 rounded-xl blur-sm" />
                        <div className="relative w-full h-full flex items-center justify-center bg-white/80 rounded-xl backdrop-blur-sm border border-white/20 shadow-lg
                                      group-hover:scale-105 transition-transform duration-300">
                            {React.cloneElement(icon, {
                                className: "w-12 h-12 stroke-blue-600"
                            })}

                            {/* Corner accents */}
                            <div className="absolute top-0 left-0 w-2 h-2 border-t border-l border-blue-400 opacity-60" />
                            <div className="absolute top-0 right-0 w-2 h-2 border-t border-r border-pink-400 opacity-60" />
                            <div className="absolute bottom-0 left-0 w-2 h-2 border-b border-l border-blue-400 opacity-60" />
                            <div className="absolute bottom-0 right-0 w-2 h-2 border-b border-r border-pink-400 opacity-60" />
                        </div>
                    </div>

                    {/* Step number indicator */}
                    <div className="absolute top-0 right-0 -mt-2 -mr-2">
                        <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-600 to-pink-400 flex items-center justify-center">
                            <span className="text-white text-sm font-bold">{title.split(" ")[0]}</span>
                        </div>
                    </div>
                </div>

                {/* Content */}
                {/*text-xl font-bold bg-gradient-to-br from-blue-600 to-pink-400 bg-clip-text text-transparent drop-shadow-sm*/}
                <h3 className="text-xl font-bold bg-gradient-to-br from-pink-600 to-blue-400 bg-clip-text text-transparent drop-shadow-sm">
                    {title}
                </h3>
                <p className="font-bold">
                    {description}
                </p>

                {/* Corner decorations */}
                <div className="absolute top-0 left-0 w-4 h-4 border-l-2 border-t-2 border-blue-300/20 rounded-tl" />
                <div className="absolute top-0 right-0 w-4 h-4 border-r-2 border-t-2 border-pink-300/20 rounded-tr" />
                <div className="absolute bottom-0 left-0 w-4 h-4 border-l-2 border-b-2 border-blue-300/20 rounded-bl" />
                <div className="absolute bottom-0 right-0 w-4 h-4 border-r-2 border-b-2 border-pink-300/20 rounded-br" />
            </div>
        </div>
    );
};

// const TermsOfService = () => {
//   // Terms of Service content (unchanged)
// };
//
// const PrivacyPolicy = () => {
//   // Privacy Policy content (unchanged)
// };

export default HomePage;